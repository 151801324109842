<template>
  <v-app>
    <v-container>
      <!-- Centered Avatar Image -->
      <v-row justify="center">
        <v-avatar size="60">
          <v-img src="avax.png" alt="Avatar"></v-img>
        </v-avatar>
      </v-row>

      <!-- Centered Social Media Icons -->
      <v-row justify="center" class="social-media-icons">
        <v-btn color="blue" class="lefticon" dark href="https://twitter.com/ordicat_btc" target="_blank">
          Twitter
        </v-btn>
        <v-btn color="green" dark href="https://inscribenow.io/collections/3cd7623d29b57a53" target="_blank">
          Mint
        </v-btn>
      </v-row>

      <!-- Page Content -->

      <!-- Other components... -->

    </v-container>
    <v-container>
      <v-row>
        <!-- TYPE Selection Area -->
        <v-col cols="3">
          <v-select
            v-model="selectedType"
            :items="features.TYPE"
            label="Select TYPE"
            multiple
          ></v-select>
        </v-col>

        <!-- GLASSES Selection Area -->
        <v-col cols="3">
          <v-select
            v-model="selectedGlasses"
            :items="features.GLASSES"
            label="Select GLASSES"
            multiple
          ></v-select>
        </v-col>

        <!-- Helmet Selection Area -->
        <v-col cols="3">
          <v-select
            v-model="selectedHelmet"
            :items="features.Helmet"
            label="Select Helmet"
            multiple
          ></v-select>
        </v-col>

        <!-- ID Search Area -->
        <v-col cols="3">
          <v-text-field v-model="searchedId" label="Search ID"></v-text-field>
        </v-col>

        <!-- Images on the Right -->
        <v-col v-for="(item, index) in filteredImages.slice(0, displayedItems)" :key="index" cols="12" sm="6" md="4" lg="3">
          <v-card class="ma-2 custom-card" outlined @click="imageClicked(item)">
            <v-img :src="item.image" :alt="'Image ' + item.id" class="image"></v-img>
            <v-card-subtitle class="cat-id">Ordi Cat #{{ item.id }}</v-card-subtitle>
          </v-card>
        </v-col>

        <!-- Infinite Scrolling -->
        <v-col v-if="!loading" cols="12">
          <v-row justify="center">
            <v-btn @click="loadMore" :loading="loading" color="primary" dark>
              Load More
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <!-- Image Modal -->
      <v-dialog v-model="modalOpen" max-width="500">
        <v-card>
          <v-img :src="modalImage" :alt="modalAlt" class="modal-image"></v-img>
          <v-divider></v-divider>
          <v-card-subtitle class="modal-features">
            <v-row v-for="(value, key) in modalFeatures" :key="key" class="modal-feature">
              <v-col cols="6" class="feature-key">{{ key }}</v-col>
              <v-col cols="6" class="feature-value">{{ value }}</v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-subtitle class="modal-buttons">
            <v-btn @click="magicedenClick" class="modal-button">Magiceden</v-btn>
            <v-btn @click="okxClick" class="modal-button">Okx</v-btn>
            <v-btn @click="downloadPngClick" class="modal-button">Download Png</v-btn>
          </v-card-subtitle>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      allData: [],
      features: {},
      selectedType: [],
      selectedGlasses: [],
      selectedHelmet: [],
      searchedId: '',
      displayedItems: 24,
      loading: false,
      modalOpen: false,
      modalImage: '',
      modalAlt: '',
      modalFeatures: {},
    };
  },
  computed: {
    filteredImages() {
      const typeFilter = this.selectedType.length === 0 ? this.allData : this.allData.filter(item => this.selectedType.includes(item.attributes.TYPE));
      const glassesFilter = this.selectedGlasses.length === 0 ? typeFilter : typeFilter.filter(item => this.selectedGlasses.includes(item.attributes.GLASSES));
      const helmetFilter = this.selectedHelmet.length === 0 ? glassesFilter : glassesFilter.filter(item => this.selectedHelmet.includes(item.attributes.Helmet));
      return this.searchedId.trim() === '' ? helmetFilter : helmetFilter.filter(item => item.id.toString().includes(this.searchedId.trim()));
    },
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      try {
        const response = await fetch('datacat/meta.json');
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
          this.allData = data;
          this.features = this.getFeatures(data);
        } else {
          console.error('Data structure is not in the expected format.');
        }
      } catch (error) {
        console.error('An error occurred while loading data:', error);
      }
    },
    getFeatures(data) {
      const features = {
        TYPE: [],
        GLASSES: [],
        Helmet: [],
      };
      data.forEach((item) => {
        features.TYPE.push(item.attributes.TYPE);
        features.GLASSES.push(item.attributes.GLASSES);
        features.Helmet.push(item.attributes.Helmet);
      });
      features.TYPE = [...new Set(features.TYPE)];
      features.GLASSES = [...new Set(features.GLASSES)];
      features.Helmet = [...new Set(features.Helmet)];
      return features;
    },
    loadMore() {
      this.loading = true;
      setTimeout(() => {
        this.displayedItems += 24;
        this.loading = false;
      }, 1000);
    },
    imageClicked(item) {
      this.modalImage = item.image;
      this.modalAlt = 'Image ' + item.id;
      this.modalFeatures = item.attributes;
      this.modalOpen = true;
    },
    magicedenClick() {
      alert('Coming Soon - Magiceden');
    },
    okxClick() {
      alert('Coming Soon - Okx');
    },
downloadPngClick() {
  const link = document.createElement('a');
  link.href = this.modalImage;
  link.download = 'image.png';
  link.click();
},
  },
};
</script>

<style scoped>
body {
  background-color: #f7931a;
  font-family: 'Roboto', sans-serif;
}

.image, .cat-id {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-weight: bold;
  color: white;
}

.custom-card {
  background-color: dark;
}

.cat-id {
  text-align: center;
  color: white;
  padding: 5px;
  margin-top: -10px;
}

.modal-image {
  width: 100%;
  object-fit: contain;
}

.modal-features {
  background-color: #f7931a;
  color: white;
}

.modal-feature {
  padding: 5px;
}

.feature-key, .feature-value {
  font-weight: bold;
}

.modal-buttons {
  text-align: center;
  background-color: #f7931a;
  padding: 10px;
}

.modal-button {
  margin: 5px;
}

.social-media-icons {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.lefticon {
    margin-right: 14px;
}
</style>
